export default function HowWeWork() {
  return (
    <div className="how">
      <div className="container">
        <div className="h1">Как мы работаем</div>
        <div className="row">
          <div className="col-sm-12 col-md-3">
            <div className="how-item">
              <div className="icon">
                <img src="/images/mashinka.png" className="car" alt="" title="" />
              </div>
              <div className="h3">Забираем</div>
              <p>Забираем вещи по временным интервалам с 10:00 до 22:00.</p>
            </div>
          </div>
          <div className="col-sm-12 col-md-1">
            <img src="/images/strelka.svg" alt="" title="" className="arr" />
          </div>
          <div className="col-sm-12 col-md-4">
            <div className="how-item">
              <div className="icon">
                <img src="/images/stiralka.png" alt="" title="" />
              </div>
              <div className="h3">Чистим</div>
              <p>Чистим, и не только, а еще реставрируем, восстанавливаем и подкрашиваем.</p>
            </div>
          </div>
          <div className="col-sm-12 col-md-1">
            <img src="/images/strelka.svg" alt="" title="" className="arr" />
          </div>
          <div className="col-sm-12 col-md-3">
            <div className="how-item">
              <div className="icon">
                <img src="/images/privozim.png" alt="" title="" />
              </div>
              <div className="h3">Доставляем</div>
              <p>Привозим вещи вовремя, чистыми и выглаженными.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
