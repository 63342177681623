export const binMapToWorkDays = (number = 0) => {
  const workDays = ['ВС', 'СБ', 'ПТ', 'ЧТ', 'СР', 'ВТ', 'ПН']
  const bin = number?.toString(2).padStart(7, '0') || '0000000'
  let resultStr = ''
  let i = 7
  let chain = 0
  while (i-- > -1) {
    if (bin[i] === '1' && i === 0 && chain > 1) {
      resultStr += ' - ' + workDays[i]
      continue
    }
    if (bin[i] === '1' && i === 0 && chain > 0) {
      resultStr += ', ' + workDays[i]
      continue
    }
    if (bin[i] === '1' && chain === 0) {
      resultStr += workDays[i]
      chain++
      continue
    }
    if (bin[i] === '1' && chain > 0) {
      chain++
      continue
    }
    if (bin[i] === '0' && chain > 2) {
      resultStr += (' - ' + workDays[i + 1]) + ', '
      chain = 0
      continue
    }
    if (bin[i] === '0' && chain > 1) {
      resultStr += (', ' + workDays[i + 1]) + ', '
      chain = 0
      continue
    }
    if (bin[i] === '0' && chain > 0) {
      resultStr += ', '
      chain = 0
      continue
    }
  }
  if (resultStr[resultStr.length - 2] === ',') resultStr = resultStr.slice(0, resultStr.length - 2)
  return resultStr
}
