import { useEffect } from 'react'

export default function MainSection() {
  useEffect(() => {
    const bubbles = Array.from(document.getElementsByClassName('bubble') as HTMLCollectionOf<HTMLElement>)
    bubbles.forEach((bubble) => {
      const duration = bubble.clientWidth / 100 + 1
      bubble.style.transitionDuration = `${duration}s`
    })

    function onScroll() {
      const st = window.scrollY
      if (st > 0 && st < 700) {
        bubbles.forEach((bubble) => {
          bubble.style.transform = `translateY(-${st / 10}px)`
        })
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <div className="index-top">
      <div className="container relative">
        <div className="bubble b1"></div>
        <div className="bubble b2"></div>
        <div className="bubble b3"></div>
        <div className="bubble b4"></div>
        <div className="bubble b5"></div>
        <div className="star star1"></div>
        <div className="star star2"></div>
        <div className="star star3"></div>
        <div className="star star4"></div>
        <div className="star star5"></div>
        <div className="star star6"></div>
        <div className="star star7"></div>
        <div className="text">
          <h1>Химчистка с доставкой</h1>
          <p>Курьер - 15 мин - бесплатно</p>
        </div>
        <div className="zaehat">
          <div>
            {/* <div className="form">
              <div className="h4">Мы можем заехать к вам:</div>
              <input type="hidden" value="Сегодня. 21 апреля 12:00-14:00" readOnly />
              <div className="current" id="selDate">
                Сегодня. 21 сентября 12:00-14:00
              </div>
              <div className="z-drop">
                <a href="#" className="val">
                  Сегодня. 21 сентября 10:00-12:00
                </a>
                <a href="#" className="val">
                  Сегодня. 21 сентября 12:00-14:00
                </a>
                <a href="#" className="val">
                  Сегодня. 21 сентября 14:00-16:00
                </a>
                <div className="other">
                  <a href="#">Другое время</a>
                </div>
              </div>
            </div> */}
            <a href="https://wa.me/79689259317">
              <button>Заберите мои вещи</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
