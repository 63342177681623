import Link from 'next/link'

import { TCategory } from 'types/Category'
import { categoryAPI } from '../../services'

interface Props {
  category: TCategory
}
const CategoryCart: React.FC<Props> = (props): JSX.Element => {
  const { category } = props
  const [click] = categoryAPI.useInfoClickMutation()

  const handleClick = () => {
    const clickInfo = {
      categoryId: category.id,
    }
    return click(clickInfo)
  }

  return (
    <Link href={`/uslugi/${category.id}`} passHref>
      <div onClick={handleClick} className="one-cat">
        <img src={`/api/client/services/categories/${category.id}/image`} style={{ 
          position: 'absolute', 
          maxHeight: '70px', 
          minHeight: '70px', 
          top: '50%', 
          right: '15px', 
          transform: 'translate(0, -50%)' 
        }} />
        <div className="icon" style={{ position: 'relative', height: '100%' }}>
        </div>
        <div className="text" style={{ zIndex: 1 }}>
          <div className="title" style={{ lineHeight: '1em' }}>
            {category.name}
          </div>
          <div className="sub-title">{category.description}</div>
        </div>
      </div>
    </Link>
  )
}

export default CategoryCart
