import ServiceCart from 'components/ServiceCart'
import CategoryCart from 'components/CategoryCart'
import SearchInput from 'components/SearchInput'

import { useRouter } from 'next/router'
import { categoryAPI, serviceAPI } from 'services'
import { filterServicesByName, filterServicesByVisibility } from 'helpers/filters'
import { numberFormat } from 'helpers/format'

export default function Services() {
  const {
    query: { search },
  } = useRouter()
  const services = serviceAPI.useGetQuery()
  const settings = serviceAPI.useSettingsQuery()
  const categories = categoryAPI.useGetQuery()

  const filteredServices = search ? filterServicesByName(services.data, search) : filterServicesByVisibility(services.data)


  return (
    <div className="container container-big">
      <div className="price-block on-index">
        <div className="category-list">
          <div className="row row-16">
            {categories.isSuccess &&
              categories.data
                .slice()
                .sort((a, b) => b.priority - a.priority)
                .map((category) => (
                  <div key={category.id} className="col-md-6 col-lg-4 col-xl-3">
                    <CategoryCart category={category} />
                  </div>
                ))}
          </div>
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />

        <div className="row">
          <div className="col-xl-4 col-lg-3 col-md-3">
            <div className="h1" style={{ fontSize: '36px' }}>
              Популярные услуги
            </div>
          </div>
          <div className="col-xl-8 col-lg-9 col-md-9">
            <SearchInput />
          </div>
        </div>

        <div className="item-list">
          <div className="row row-8">
            {services.isSuccess &&
              filteredServices
                ?.slice()
                .sort((a, b) => b.priority - a.priority)
                .map((service) => (
                  <div key={service.id} className="col-md-6 col-xl-4">
                    <ServiceCart service={service} />
                  </div>
                ))}
          </div>
        </div>
        {settings.isSuccess && (
          <div className="pb-text">
            <p>
              Стоимость доставки: Москва {numberFormat(settings.data.deliveryPrice)} <span className="rub" />, при
              заказе от {numberFormat(settings.data.freeDeliveryAmount)} <span className="rub" /> —{' '}
              <strong>бесплатно</strong>.
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
