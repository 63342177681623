import { TAddress } from 'types/Adress'
import dateFormat from 'dateformat'

export function formatPhoneNumber(phoneNumberString: string | undefined = '') {
  const cleaned = phoneNumberString.replace(/\D/g, '')
  if (cleaned.length === 12) {
    // FOR KG NUMBERS
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{3})$/)
    if (!match) return null
    return `+${match[1]} (${match[2]}) ${match[3]} ${match[4]}`
  } else {
    // FOR RU NUMBERS
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/)
    if (!match) return null
    return `+${match[1]} (${match[2]}) ${match[3]} ${match[4]} ${match[5]}`
  }
}

export function parsePhoneNumber(phoneNumberString: string): string {
  return `+` + phoneNumberString.replace(/\D/gi, '')
}

export function parseNumber(numberString: string): string {
  return numberString.replace(/\D/gi, '')
}

export function getAddressString(address: TAddress) {
  const addressArray: string[] = []

  if (address.address) addressArray.push(address.address)
  if (address.entrance) addressArray.push('подьезд ' + address.entrance)
  if (address.flat) addressArray.push('к. ' + address.flat)
  if (address.floor) addressArray.push('этаж ' + address.floor)
  if (address.intercomCode) addressArray.push('код подъезда ' + address.intercomCode)

  return addressArray.join(', ')
}

export function numberFormat(number: number, locale = 'ru-RU') {
  return Intl.NumberFormat(locale).format(number)
}

export function getDate(str: string): string {
  return dateFormat(new Date(str), 'dd.mm.yyyy')
}

export function getUTCDate(date: string, time?: string): string {
  if (!date) return ''

  const dateObj = new Date()
  const [day, month, year] = date.split('.').map((n) => Number(n))
  let hours = dateObj.getHours()
  if (time) {
    const [startTime] = time.split('-').map((t) => t.split(':'))
    hours = Number(startTime[0])
  }

  if (!day || !month || !year) return dateObj.toJSON()

  return new Date(year, month - 1, day, hours).toJSON()
}
