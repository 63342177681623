import { binMapToWorkDays } from 'helpers/binMap'
import moment from 'moment-timezone'
import React, { useEffect, useRef } from 'react'
import { GeoObjectProps, Placemark, PlacemarkGeometry } from 'react-yandex-maps'
import { TPoint } from 'services/MapService'

type Props = {
  point: TPoint,
  selectedMark: number,
  index: number
}

const balloonContent = (point: TPoint) => {
  const clientTimezone = moment.tz.guess();

  let str = `${point.address} </br> Время работы: ${binMapToWorkDays(point.workDays)} ${moment.utc(point.startWorkTime).tz(clientTimezone).format("HH:mm")} - ${moment.utc(point.endWorkTime).tz(clientTimezone).format("HH:mm")} 
  <br />`
  if (point.cardLink) {
    str += `<a href="${point.cardLink}">Открыть в Яндекс картах</a>`
  }

  return str
}

const Point = (props: Props) => {
  const { point, selectedMark, index } = props
  const markRef = useRef<GeoObjectProps<PlacemarkGeometry>>(null)

  useEffect(() => {
    if (selectedMark === index) {
      openBalloon()
    }
  }, [index, selectedMark])

    const openBalloon = () => {
      if (markRef) {
        setTimeout(() => {
          try {
            markRef.current?.balloon.open()
          } catch (error) {}
        }, 100)
      }        
  }

  return (
    <Placemark
    in
    key={point.address}
    geometry={point.coordinates?.x && point.coordinates?.y ? [point.coordinates.y, point.coordinates.x] : [0,0]}
    options={{
      iconLayout: 'default#image',
      iconImageHref: selectedMark === index ? '/images/selected_marker.png' : '/images/marker.png',
      iconImageSize: [30, 40],
      iconImageOffset: [-15, -20],
    }}
    properties={{
      balloonContentHeader: "Пункт приема",
      balloonContent: balloonContent(point),
      hideIconOnBalloonOpen: false,
    }}
    modules={['geoObject.addon.balloon']}
    instanceRef={markRef} 
  />
  )
}

export default Point