// import Faq from 'particials/Faq'
import Map from 'particials/Map'
import WeAreTrusted from 'particials/WeAreTrusted'
import HowWeWork from 'particials/HowWeWork'

// import Partners from './Partners'
import Services from './Services'
import MainSection from './MainSection'
import { breadCrumb, organization, searchAction } from 'constants/microMarkup'

export default function HomeContainer(): JSX.Element {
  return (
    <>
      <MainSection />

      {/* <div className="order-block">
        <div className="container container-big">
          <div className="row">
            <div className="col-3 stirka">
              <div className="text">
                <div className="h3">
                  <span>Стирка и глажка</span>
                </div>
                <p>
                  Забираем, стираем, гладим и доставляем прямо домой, когда позовете. Ваши вещи чистые и отглаженные.
                </p>
              </div>
              <a href="#" className="link">
                Заказать
              </a>
            </div>
            <div className="col-3 restore">
              <div className="text">
                <div className="h3">
                  <span>Реставрация кожи и обуви</span>
                </div>
                <p>
                  Чистка, покраска, выведение реагентов, реставрация царапин и потертостей. Все сделаем первокласно.
                </p>
              </div>
              <a href="#" className="link">
                Заказать
              </a>
            </div>
            <div className="col-3 himia">
              <div className="text">
                <div className="h3">
                  <span>Химчистка</span>
                </div>
                <p>Неизменное качество химчистки Four Bubbles, если не пробовали - сейчас самое время.</p>
              </div>
              <a href="#" className="link">
                Заказать
              </a>
            </div>
            <div className="col-3 atelie">
              <div className="text">
                <div className="h3">
                  <span>Ателье</span>
                </div>
                <p>
                  Ремонт одежды в ателье Four Bubbles это отличный выбор: лучшие мастера, высокое качество работ и
                  материалов, премиальный сервис
                </p>
              </div>
              <a href="#" className="link">
                Заказать
              </a>
            </div>
          </div>
        </div>
      </div> */}
      <Services />
      <HowWeWork />
      {/* <div className="fast-link">
        <div className="container container-big">
          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="one stars">
                <img src="/images/stars_bubble.png" alt="" title="" className="stars_bubble" />
                <a href="stars.html" className="image">
                  <img src="/images/stars.png" alt="" title="" />
                </a>
                <a href="stars.html" className="title">
                  Нам доверяют звезды
                </a>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="one save">
                <img src="/images/save_bubble.png" alt="" title="" className="save_bubble" />
                <a href="save.html" className="image">
                  <img src="/images/save.png" alt="" title="" />
                </a>
                <a href="save.html" className="title">
                  Мы спасли эти вещи
                </a>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="one franshiza">
                <img src="/images/franshiza_bubble.png" alt="" title="" className="franshiza_bubble" />
                <a href="franshiza.html" className="image">
                  <img src="/images/franshiza.png" alt="" title="" />
                </a>
                <a href="franshiza.html" className="title">
                  Откройте франшизу
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Map />
      {/* <div className="covid">
        <div className="container">
          <div className="h2">БЕЗОПАСНО ПРИ ПАНДЕМИИ</div>
          <div className="h3">Как мы работаем в условиях пандемии</div>
        </div>
        <div className="container container-big">
          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="obrabotka">
                <div className="title">Обрабатываем все поверхности в рабочих пространствах</div>
                <p>
                  Мы несколько раз за день дезинфицируем все цеха нашей фабрики, рабочие поверхности всех приемных
                  пунктов, а также используем ультрафиолетовые лампы для дополнительной дезинфекции помещений.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="proverka">
                <div className="title">Проверяем состояние здоровья наших сотрудников</div>
                <p>
                  Ежедневно замеряем температуру всех сотрудников перед выходом на работу, а также каждые 4 часа в
                  течение смены.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="maska">
                <div className="title">Обслуживаем безопасно</div>
                <p>Наши сотрудники регулярно обрабатывают руки антисептиками, а также работают в перчатках и масках.</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <Faq /> */}
      {/* <Partners /> */}
      <WeAreTrusted />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(organization) }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(searchAction) }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(breadCrumb) }}
      />
    </>
  )
}
