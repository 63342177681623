import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'

function WeAreTrusted(): JSX.Element | null {
  if (typeof window === 'undefined') return null

  return null

  return (
    <div className="namd">
      <div className="container">
        <div className="h2">НАМ ДОВЕРЯЮТ</div>
      </div>
      <div className="swiper-container" id="dswiper">
        <Swiper loop={true} freeMode={true} centeredSlides={true} spaceBetween={20} slidesPerView="auto">
          <SwiperSlide>
            <img src="/images/slide/1.jpg" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/slide/2.jpg" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/slide/3.jpg" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/slide/4.jpg" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/slide/5.jpg" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/slide/6.jpg" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/slide/7.jpg" />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  )
}

export default WeAreTrusted
