import Image from 'next/image'

import { TService } from 'types/Service'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import {
  addItemToBasket,
  incrementBasketItem,
  decrementBasketItem,
  deleteBasketItem,
  selectBasketItem,
  setCountBasketItem,
} from 'store/features/basketSlice'
import { getDatePluralForm } from 'helpers/getDatePluralForm'
import { useState } from 'react'

interface Props {
  service: TService
  isDeletable?: boolean
}
const ServiceCart = ({ service, isDeletable }: Props) => {
  const dispatch = useAppDispatch()
  const count = useAppSelector(selectBasketItem(service.id)) || 0
  const [inputReadOnly, setInputReadOnly] = useState(true)
  const [inputCount, setInputCount] = useState(count)

  const handleClickIncrement = () => {
    count ? dispatch(incrementBasketItem(service.id)) : dispatch(addItemToBasket(service.id))
    setInputCount(inputCount + 1)
  }
  const handleClickDecrement = () => {
    dispatch(decrementBasketItem(service.id))
    setInputCount(inputCount - 1)
  }
  const handleClickDelete = () => {
    dispatch(deleteBasketItem(service.id))
  }

  const handleClick = () => {
    setInputReadOnly(false)
  }

  const onChangeCount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const count = Number(e.target.value) | 0
    setInputCount(count)
    dispatch(setCountBasketItem([service.id, count]))
  }

  const onBlurInput = () => {
    setInputReadOnly(true)
  }

  return (
    <div className="one-item">
      <div className="icon">
        <Image src={`/api/client/services/${service.id}/image`} width={60} height={60} />
      </div>
      <div className="text">
        <div className="title">{service.name}</div>
        <div className="i-price">
          {service.price} <span className="rub" /> / {service.unit === 'кв.м.' ? 'м²' : service.unit}
        </div>
        <div className="srok">
          Срок чистки {service.cleaningTime} {getDatePluralForm(service.cleaningTime)}
        </div>
      </div>
      <div className="plus-minus">
        <span className="plus" onClick={handleClickIncrement}></span>
        <input placeholder="0" type="text" value={inputReadOnly ? count : inputCount} name="count" readOnly={inputReadOnly} onClick={handleClick} onChange={onChangeCount} onBlur={onBlurInput} />
        <span className="minus" onClick={handleClickDecrement}></span>
      </div>
      {isDeletable && <a onClick={handleClickDelete} className="delete-item"></a>}
    </div>
  )
}

export default ServiceCart
