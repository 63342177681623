export const organization = {
  "@context": "https://schema.org",
  "@type": "Organization",
  "url": "https://4b.ru/",
  "name": "4b.ru Химчистка",
  "email": "info@4b.ru",
  "logo": "https://4b.ru/images/logo.svg",
  "description": "Химчистка одежды", "address": {
    "@type": "PostalAddress",
    "addressLocality": "Москва, Россия",
    "postalCode": "127254",
    "streetAddress": " Огородный проезд, 10"
  }, "contactPoint": [
    {
      "@type": "ContactPoint",
      "telephone": "8 800-700-23-24",
      "contactType": "customer service"
    }]
}

export const searchAction = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  "url": "https://4b.ru/",
  "potentialAction": {
    "@type": "SearchAction",
    "target": "https://4b.ru/search?search={search_term_string}",
    "query-input": "required name=search_term_string"
  }
}

export const breadCrumb = {
  "@context": "http://schema.org/",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": {
      "@id": "https://4b.ru/",
      "name": "{РҐРёРјС‡РёСЃС‚РєР°"
    }
  }, {
    "@type": "ListItem",
    "position": 2,
    "item": {
      "@id": "https://4b.ru/#",
      "name": "&#128680; &#128680; &#128680;"
    }
  }]
}